<template>
	<div>
		<div class="container">
			<!-- 背景 -->
			<img class="back-ground-image" src="@/assets/image/idx-renkou.png" alt="" />
			<!-- 顶部 -->
			<div class="header"><img class="header-logo" src="../../assets/image/wjlogo.png" /> 旺家·东泰共建数字社区</div>
			<!-- 返回 刷新 -->
			<rightNav></rightNav>
			<!-- 时间 -->
			<div class="time-box">
				<Date></Date>
			</div>
			<!-- top -->
			<div class="renkou-top">
				<div class="renkou-top-L">
					<!-- 社区空间 -->
					<ShequKj @dialog_click="dialog_click"></ShequKj>
					<!-- 社区空间弹框 -->
					<Dialog :dialog="edit_dialog" :dialogTitle="dialogTitle" :dataList="dataList"> </Dialog>
				</div>
				<div class="renkou-top-M">
					<!-- 人口概况 -->
					<RenkouGk @dialog_click="dialog_click"></RenkouGk>
					<!-- 人口概况弹框 -->
					<Dialog :dialog="edit_dialog" :dialogTitle="dialogTitle" :dataList="dataList"> </Dialog>
				</div>
				<div class="renkou-top-R">
					<!-- 长者服务 -->
					<!-- <ZhangzheFw @dialog_click="dialog_click"></ZhangzheFw> -->
					<!-- 长者服务弹框 -->
					<!-- <Dialog :dialog="edit_dialog" :dialogTitle="dialogTitle" :dataList="dataList"> </Dialog> -->
					<huoJiang></huoJiang>
				</div>
			</div>
			<!-- bottom -->
			<div class="renkou-bottom">
				<!-- 各小区常住人口对比 -->
				<XiaoqumenNum @dialog_click="dialog_click"></XiaoqumenNum>
				<!-- 各小区常住人口对比弹框 -->
				<Dialog :dialog="edit_dialog" :dialogTitle="dialogTitle" :dataList="dataList"> </Dialog>
			</div>
		</div>
	</div>
</template>

<script>
import Dialog from '@/components/dialog/dialog.vue';
import Demo from './components/demo.vue';
import Date from '@/views/commonComponents/date.vue';
import ShequKj from './components/shequKj.vue';
import XiaoqumenNum from './components/xiaoqumenNum.vue';
import RenkouGk from './components/renkouGk.vue';
import ZhangzheFw from './components/zhangzheFw.vue';
import rightNav from '@/views/commonComponents/rightNav.vue';
import huoJiang from './components/huoJiang.vue';

export default {
	components: {
		Demo,
		Date,
		ShequKj,
		XiaoqumenNum,
		RenkouGk,
		ZhangzheFw,
		rightNav,
		Dialog,
		huoJiang,
	},
	data() {
		return {
			edit_dialog: false,
			dialogTitle: '',
			dataList: [], // 传递数据
		};
	},
	created() {},
	methods: {
		// 弹框出现
		dialog_click(t, e) {
			if (!this.existSystem()) {
				return false;
			}
			console.log('实际数据', e);
			(this.dialogTitle = t), (this.dataList = e);
			console.log(t, e);
			this.edit_dialog = true;
		},
	},
	mounted() {},
};
</script>

<style scoped lang="scss">
.container {
	width: 100%;
	height: auto;
	overflow: hidden;
	.back-ground-image {
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		z-index: -1;
	}
	// 顶部
	.header {
		width: 100%;
		height: 10vh;
		line-height: 10vh;
		// letter-spacing: 1vw;
		// padding-left: 1vw;
		// background: linear-gradient(to top, #c0d9ff, #fff);
		background: linear-gradient(to top, #c0d9ff, #fff);
		-webkit-background-clip: text;
		color: transparent;
		// font-size: 2.5vw;
		text-align: center;
		font-family: Microsoft YaHei;
		font-weight: bold;
		// color: #FFFFFF;
		text-shadow: 0px 10px 7px rgba(0, 0, 0, 0.17);
	}
	.time-box {
		width: 25vw;
		height: 7vh;
		// border: 1px solid teal;
		position: fixed;
		top: 7vh;
		right: 3vw;
		z-index: 99;
	}
	// top
	.renkou-top {
		width: 100vw;
		height: 51vh;
		// border: 1px solid tomato;
		.renkou-top-L {
			width: 22.5vw;
			height: 51vh;
			// border: 1px solid tan;
			margin-left: 2vw;
			float: left;
		}
		.renkou-top-M {
			width: 32.5vw;
			height: 45vh;
			// border: 1px solid tan;
			margin-left: 4.3vw;
			margin-top: 6vh;
			float: left;
		}
		.renkou-top-R {
			width: 30.5vw;
			height: 45vh;
			float: left;
			margin-left: 4vw;
			// border: 1px solid tan;
			margin-top: 6vh;
		}
	}
	//   bottom
	.renkou-bottom {
		width: 100vw;
		height: 31vh;
		// border: 1px solid tomato;
		margin-top: 6vh;
		position: relative;
	}
}
</style>
