<template>
	<div class="xqu-middle-box">
		<div class="xqu-middle-1">社区空间</div>
		<div class="xqu-middle-2box">
			<div class="xqu-middle-2box-1" @click="go">
				<div class="xqu-box1">
					<div class="xqu-box1-1">辖区面积(亩)</div>
					<div class="xqu-box1-2">
						<img class="xqu-box1-image" src="@/assets/icon/shequ-1.png" alt="" />
						<span>{{ getdatavalue(420) }}</span>
					</div>
				</div>
				<div class="xqu-box1">
					<div class="xqu-box1-1">建筑面积(㎡)</div>
					<div class="xqu-box1-2">
						<img class="xqu-box1-image" src="@/assets/icon/shequ-2.png" alt="" />
						<span>{{ getdatavalue(421) }}万</span>
					</div>
				</div>
				<div class="xqu-box1">
					<div class="xqu-box1-1">绿化面积(㎡)</div>
					<div class="xqu-box1-2">
						<img class="xqu-box1-image" src="@/assets/icon/shequ-3.png" alt="" />
						<span>{{ getdatavalue(422) }}万</span>
					</div>
				</div>
			</div>
			<div class="xqu-middle-2box-2" @click="go1">
				<div class="xqu-2box-2a-1">
					<div class="xqu-2box-2a-1a">
						<div class="xqu-2box-2a-1aL">
							<img class="xqu-box1-image" src="@/assets/icon/shequ-4.png" alt="" />
						</div>
						<div class="xqu-2box-2a-1aR">
							<span>建筑栋数</span>
							<span>{{ getdatavalue(423) }}</span>
						</div>
					</div>
					<!-- <div class="xqu-2box-2a-1b">
            <div class="xqu-2box-2a-1box">
              <span>( </span>
              <span>高层：</span>
              <span class="xqu-s1">{{ getdatavalue(232) }}</span>
              <span>别墅：</span>
              <span class="xqu-s1">{{ getdatavalue(233) }}</span>
              <span>其它：</span>
              <span class="xqu-s1">{{ getdatavalue(234) }}</span>
              <span> )</span>
            </div>
          </div> -->
				</div>
				<div class="xqu-2box-2a-1">
					<div class="xqu-2box-2a-1a">
						<div class="xqu-2box-2a-1aL">
							<img class="xqu-box1-image" src="@/assets/icon/shequ-5.png" alt="" />
						</div>
						<div class="xqu-2box-2a-1aR">
							<span>总户数</span>
							<span>{{ getdatavalue(424) }}</span>
						</div>
					</div>
					<!-- <div class="xqu-2box-2a-1b">
            <div class="xqu-2box-2a-1box">
              <span class="xqu-s1a">( </span>
              <span>住宅：</span>
              <span class="xqu-s1">{{ getdatavalue(236) }}</span>
              <span>商铺：</span>
              <span class="xqu-s1">{{ getdatavalue(237) }}</span>
              <span> )</span>
            </div>
          </div> -->
				</div>
			</div>

			<div class="xqu-middle-2box-4">
				<div class="xqu-middle-2box-4L" @click="go2">
					<ul>
						<li>
							<div>
								<span>{{ getdatavalue(425) }}</span>
								<span>个</span>
							</div>
							<div class="sq-box">卫生服务站</div>
						</li>
						<li>
							<div>
								<span>{{ getdatavalue(426) }}</span>
								<span>间</span>
							</div>
							<div class="sq-box">商铺总数</div>
						</li>
						<li>
							<div>
								<span>{{ getdatavalue(427) }}</span>
								<span>间</span>
							</div>
							<div class="sq-box">综合超市</div>
						</li>
					</ul>
				</div>
				<div class="xqu-middle-2box-4R" @click="go3">
					<ul class="squ-ul">
						<li>
							<span>幼儿园</span>
							<span>{{ getdatavalue(428) }}</span>
							<span>间</span>
						</li>
						<li>
							<span>小学</span>
							<span>{{ getdatavalue(429) }}</span>
							<span>间</span>
						</li>
						<li>
							<span>中学</span>
							<span>{{ getdatavalue(430) }}</span>
							<span>间</span>
						</li>
					</ul>
					<!-- <ul class="squ-ul squ-ul2">
            <li>
              <span>自住房</span>
              <span>{{ getdatavalue(304) }}</span>
              <span>间</span>
            </li>
            <li>
              <span>出租房</span>
              <span>{{ getdatavalue(305) }}</span>
              <span>间</span>
            </li>
            <li>
              <span>空置房</span>
              <span>{{ getdatavalue(306) }}</span>
              <span>间</span>
            </li>
          </ul> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { listData, getvalue } from '@/api/dtdata';
export default {
	components: {},
	props: ['dialog_click'],
	data() {
		return {
			dataOptions: [],
			queryParams: {
				dataModule: 'wj_happy_home',
				dataTyp: 'home_space',
			},
		};
	},
	components: {},
	computed: {},
	created() {
		listData(this.queryParams).then((response) => {
			this.dataOptions = response.rows;
		});
	},

	methods: {
		go() {
			let arr = [
				{ dataId: '420', label: '辖区面积(㎡)', dataDetailed: this.getdatavalue(420) },
				{ dataId: '421', label: '建筑面积(㎡)', dataDetailed: this.getdatavalue(421) },
				{ dataId: '422', label: '绿化面积(㎡)', dataDetailed: this.getdatavalue(422) },
			];
			this.$emit('dialog_click', '社区空间', arr);
		},
		go1() {
			let arr = [
				{ dataId: '423', label: '建筑栋数', dataDetailed: this.getdatavalue(423) },
				{ dataId: '424', label: '总户数', dataDetailed: this.getdatavalue(424) },
				// { dataId: '232', label: '高层', dataDetailed: this.getdatavalue(232) },
				// { dataId: '233', label: '别墅', dataDetailed: this.getdatavalue(233) },
				// { dataId: '234', label: '其它', dataDetailed: this.getdatavalue(234) },
				// { dataId: '235', label: '总户数', dataDetailed: this.getdatavalue(235) },
				// { dataId: '236', label: '住宅', dataDetailed: this.getdatavalue(236) },
				// { dataId: '237', label: '商铺', dataDetailed: this.getdatavalue(237) },
			];
			this.$emit('dialog_click', '社区空间', arr);
		},
		go2() {
			let arr = [
				// { dataId: '238', label: '商务楼宇', dataDetailed: this.getdatavalue(238) },
				// { dataId: '239', label: '企业总数', dataDetailed: this.getdatavalue(239) },
				// { dataId: '240', label: '医疗场所', dataDetailed: this.getdatavalue(240) },
				// { dataId: '241', label: '家庭医生签约率', dataDetailed: this.getdatavalue(241) },
				// { dataId: '242', label: '物业公司', dataDetailed: this.getdatavalue(242) },
				// { dataId: '243', label: '覆盖率', dataDetailed: this.getdatavalue(243) },
				{ dataId: '425', label: '卫生服务站', dataDetailed: this.getdatavalue(425) },
				{ dataId: '426', label: '商铺总数', dataDetailed: this.getdatavalue(426) },
				{ dataId: '427', label: '综合超市', dataDetailed: this.getdatavalue(427) },
			];
			this.$emit('dialog_click', '社区空间', arr);
		},
		go3() {
			let arr = [
				// { dataId: '301',label: "学校",dataDetailed: this.getdatavalue(301) },
				// { dataId: '302',label: "幼儿园",dataDetailed: this.getdatavalue(302) },
				// { dataId: '303',label: "早托教育",dataDetailed: this.getdatavalue(303) },
				// { dataId: '304',label: "自住房",dataDetailed: this.getdatavalue(304) },
				// { dataId: '305',label: "出租房",dataDetailed: this.getdatavalue(305) },
				// { dataId: '306',label: "空置房",dataDetailed: this.getdatavalue(306) },
				{ dataId: '428', label: '幼儿园', dataDetailed: this.getdatavalue(428) },
				{ dataId: '429', label: '小学', dataDetailed: this.getdatavalue(429) },
				{ dataId: '430', label: '中学', dataDetailed: this.getdatavalue(430) },
			];
			this.$emit('dialog_click', '社区空间', arr);
		},
		getdatavalue(id) {
			return getvalue(this.dataOptions, id);
		},
	},
};
</script>

<style scoped lang="scss">
.xqu-middle-box {
	width: 100%;
	height: 61vh;
	overflow: hidden;
	//   border: 1px solid tan;
	margin-top: 0vh;
	.xqu-middle-1 {
		width: 100%;
		height: 6vh;
		line-height: 6vh;
		text-align: center;
		font-size: 0.9vw;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #ffd901;
	}
	.xqu-middle-2box {
		margin-top: 2.5vh;
		height: 42vh;
		.xqu-middle-2box-1 {
			width: 95%;
			height: 9vh;
			overflow: hidden;
			background: rgba(14, 42, 83, 1);
			//   border: 1px solid thistle;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-orient: horizontal;
			-webkit-box-direction: normal;
			-ms-flex-direction: row;
			flex-direction: row;
			-webkit-box-pack: justify;
			-ms-flex-pack: justify;
			justify-content: space-between;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			margin: 0 auto;
			.xqu-box1 {
				width: 33.3%;
				height: 4.5vh;
				// border: 1px solid thistle;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				align-items: center;
				.xqu-box1-1 {
					width: 100%;
					color: #fff;
					text-align: right;
					font-size: 0.8vw;
					padding-right: 1vw;
				}
				.xqu-box1-2 {
					font-size: 0.6vw;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #ffffff;
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					align-items: center;
					img {
						margin-left: -1.1vw;
						margin-bottom: 0.6vh;
					}
					span {
						font-size: 1.2vw;
						font-family: Adobe Heiti Std;
						font-weight: bold;
						color: #fea61c;
						background: linear-gradient(270deg, rgba(64, 223, 203, 0.89) 0%, rgba(0, 192, 250, 0.89) 100%);
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
					}
				}
			}
		}
		.xqu-middle-2box-2 {
			width: 95%;
			height: 9vh;
			overflow: hidden;
			background: rgba(14, 42, 83, 1);
			//   border: 1px solid tomato;
			margin: 0 auto;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			margin-top: 1.8vh;
			.xqu-2box-2a-1 {
				width: 50%;
				height: 10vh;
				color: #fff;
				// border: 1px solid tan;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				.xqu-2box-2a-1a {
					display: flex;
					flex-direction: row;
					justify-content: space-around;
					align-items: center;
					.xqu-2box-2a-1aL {
						img {
							margin-left: 0vw;
							margin-right: 0.7vw;
						}
					}
					.xqu-2box-2a-1aR {
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						height: 5.9vh;
						line-height: 3vh;
						span {
							&:first-child {
								font-size: 0.8vw;
							}
							&:last-child {
								width: 100%;
								font-size: 1.2vw;
								font-family: Adobe Heiti Std;
								font-weight: bold;
								color: #fea61c;
								background: -webkit-gradient(
									linear,
									right top,
									left top,
									from(rgba(64, 223, 203, 0.89)),
									to(rgba(0, 192, 250, 0.89))
								);
								background: linear-gradient(270deg, rgba(64, 223, 203, 0.89) 0%, rgba(0, 192, 250, 0.89) 100%);
								-webkit-background-clip: text;
								-webkit-text-fill-color: transparent;
							}
						}
					}
				}
				.xqu-2box-2a-1b {
					color: #fff;
					font-size: 0.4vw;
					.xqu-2box-2a-1box {
						font-size: 0.6vw;
						.xqu-s1 {
							font-size: 0.7vw;
							font-family: Adobe Heiti Std;
							font-weight: bold;
							color: #ffffff;
							background: linear-gradient(270deg, rgba(64, 223, 203, 0.89) 0%, rgba(0, 192, 250, 0.89) 100%);
							-webkit-background-clip: text;
							-webkit-text-fill-color: transparent;
							padding-right: 0.1vw;
						}
						.xqu-s1a {
							padding-left: 1vw;
						}
					}
				}
			}
		}
		.xqu-middle-2box-3 {
			width: 81%;
			height: 7.5vh;
			overflow: hidden;
			//   border: 1px solid tomato;
			margin: 0 auto;
			display: flex;
			flex-direction: row;
			justify-content: space-around;
			align-items: center;
			margin-top: 0.5vh;
			.xqu-middle-2box-3box {
				width: 18%;
				height: 6.5vh;
				line-height: 3vh;
				background: linear-gradient(183deg, #145dca, #1534c0);
				border-radius: 0.5vw;
				display: flex;
				flex-direction: column;
				justify-content: space-around;
				align-items: center;
				color: #fff;
				font-size: 0.8vw;
				span {
					&:last-child {
						font-size: 0.8vw;
						font-family: Adobe Heiti Std;
						font-weight: bold;
						color: #fea61c;
						background: -webkit-gradient(
							linear,
							right top,
							left top,
							from(rgba(64, 223, 203, 0.89)),
							to(rgba(0, 192, 250, 0.89))
						);
						background: linear-gradient(270deg, rgba(64, 223, 203, 0.89) 0%, rgba(0, 192, 250, 0.89) 100%);
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
					}
				}
			}
		}
		.xqu-middle-2box-4 {
			width: 95%;
			height: 21.5vh;
			overflow: hidden;
			//   border: 1px solid tomato;
			/* margin: 0 auto; */
			padding-right: 1vw;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			margin-top: 1vh;
			.xqu-middle-2box-4L {
				width: 50%;
				height: 21.5vh;
				overflow: hidden;
				padding-top: 2vh;
				// border: 1px solid tomato;
				ul li {
					width: 100%;
					height: 6.2vh;
					line-height: 2.6vh;
					//   border: 1px solid teal;
					float: left;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					div {
						span {
							color: #fff;
							&:first-child {
								font-size: 1.2vw;
								font-family: hy;
								font-weight: bold;
								font-style: italic;
								color: #fff7c8;
								padding-right: 0.5vw;
							}
							&:last-child {
								font-size: 0.6vw;
								font-weight: bold;
								font-style: normal;
								color: rgba(4, 243, 238, 1);
							}
						}
					}
					.sq-box {
						font-size: 0.7vw;
						font-family: Adobe Heiti Std;
						font-weight: normal;
						color: #eeab1a;
					}
				}
			}
			.xqu-middle-2box-4R {
				width: 43%;
				height: 21.5vh;
				overflow: hidden;
				padding-top: 0.8vh;
				// border: 1px solid tomato;
				.squ-ul {
					width: 98%;
					height: 16vh;
					overflow: hidden;
					background: #1c6cc3;
					/* border: 1px solid tomato; */
					margin-top: 1vh;
					display: flex;
					align-items: center;
					justify-content: space-around;
					flex-direction: column;
				}
				.squ-ul2 {
					margin-top: 1vh;
				}
				ul li {
					width: 100%;
					height: 2.8vh;
					line-height: 2.8vh;
					font-size: 0.8vw;
					span {
						&:nth-of-type(1) {
							display: inline-block;
							width: 50%;
							text-align: center;
							color: #ffffff;
						}
						&:nth-of-type(2) {
							display: inline-block;
							width: 33%;
							text-align: center;

							font-family: hy;
							font-weight: bold;
							font-style: italic;
							color: #ffd901;
						}
						&:nth-of-type(3) {
							text-align: center;
							color: #ffffff;
						}
					}
				}
			}
		}
	}
}
</style>
