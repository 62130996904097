<template>
	<div class="idx-middle-box3">
		<div class="idx-middle-1">人口概况</div>
		<div class="idx-middle-2" @click="go">
			<!-- <div class="idx-middle-2a">
        <span>{{ getdatavalue(244) }}</span>
        <span>总人口</span>
      </div> -->
			<div class="idx-middle-2a">
				<span>{{ getdatavalue(431) }}</span>
				<span>总人口</span>
			</div>
			<div class="idx-middle-2a">
				<span>{{ getdatavalue(432) }}</span>
				<span>劳动力</span>
			</div>
			<div class="idx-middle-2a">
				<span>{{ getdatavalue(433) }}</span>
				<span>区内就业</span>
			</div>
			<div class="idx-middle-2a">
				<span>{{ getdatavalue(434) }}</span>
				<span>区外就业</span>
			</div>
			<!-- <div class="idx-middle-2b">
        <div class="idx-middle-2d-t">
          <span>本月迁入总人数</span>
          <span class="rk-s1">{{ getdatavalue(247) }}</span>
          <span>人，迁出</span>
          <span class="rk-s1">{{ getdatavalue(248) }}</span>
          <span>人</span>
        </div>
        <div class="idx-middle-2d-b">
          <span>本月出生总人数</span>
          <span class="rk-s1">{{ getdatavalue(249) }}</span>
          <span>人，死亡</span>
          <span class="rk-s1">{{ getdatavalue(250) }}</span>
          <span>人</span>
        </div>
      </div> -->
		</div>
		<div class="idx-middle-3">
			<div class="idx-middle-3-L" @click="go1">
				<ul>
					<!-- <li>
						<div>
							<span class="idx-s3">独生子女</span>
							<span>{{ getdatavalue(251) }}</span>
							<span class="idx-s3">人</span>
						</div>
						<div class="sq-box">独生子女父母奖励金：{{ getdatavalue(307) }}元/人</div>
					</li>
					<li>
						<div>
							<span class="idx-s3">失独老人</span>
							<span>{{ getdatavalue(252) }}</span>
							<span class="idx-s3">对</span>
						</div>
						<div class="sq-box">政府补贴{{ getdatavalue(308) }}元/月</div>
					</li> -->
					<li>
						<div>
							<span class="idx-s3 idx-s3a">{{ getdatavalue(435) }}</span>
							<span>/</span>
							<span>{{ getdatavalue(436) }}</span>
						</div>
						<div class="sq-box">低保户（户数/人数）</div>
					</li>
					<!-- <li>
						<div>
							<span class="idx-s3 idx-s3a">{{ getdatavalue(255) }}</span>
							<span>/</span>
							<span>{{ getdatavalue(256) }}</span>
							<span class="idx-s3b">%</span>
						</div>
						<div class="sq-box">优抚对象（人数/就业率）</div>
					</li>
					<li>
						<div>
							<span class="idx-s3 idx-s3a">{{ getdatavalue(257) }}</span>
							<span>/</span>
							<span>{{ getdatavalue(258) }}</span>
						</div>
						<div class="sq-box">低收入（户数/人数）</div>
					</li> -->
					<li>
						<div>
							<span class="idx-s3 idx-s3a">{{ getdatavalue(437) }}</span>
						</div>
						<div class="sq-box">残疾人士</div>
					</li>
					<li>
						<div>
							<span class="idx-s3 idx-s3a">{{ getdatavalue(438) }}</span>
						</div>
						<div class="sq-box">精神障碍人士</div>
					</li>
				</ul>
			</div>
			<div class="idx-middle-3-R" @click="go2">
				<div class="idx-middle-3-R-T">
					<div class="left">志愿服务队概况</div>
					<div class="right">
						总人数：{{
							+getdatavalue(439) +
							+getdatavalue(440) +
							+getdatavalue(441) +
							+getdatavalue(442) +
							+getdatavalue(443) +
							+getdatavalue(444) +
							+getdatavalue(445)
						}}
					</div>
				</div>
				<div class="idx-middle-3-R-B">
					<div class="shequ-1">
						<div class="shequ-1a">应急救援服务队</div>
						<div class="shequ-1b">{{ getdatavalue(439) }}</div>
					</div>
					<div class="shequ-1">
						<div class="shequ-1a">政策宣讲服务队</div>
						<div class="shequ-1b">{{ getdatavalue(440) }}</div>
					</div>
					<div class="shequ-1">
						<div class="shequ-1a">创业就业服务队</div>
						<div class="shequ-1b">{{ getdatavalue(441) }}</div>
					</div>
					<div class="shequ-1">
						<div class="shequ-1a">心理疏导服务队</div>
						<div class="shequ-1b">{{ getdatavalue(442) }}</div>
					</div>
					<div class="shequ-1">
						<div class="shequ-1a">文体文艺服务队</div>
						<div class="shequ-1b">{{ getdatavalue(443) }}</div>
					</div>
					<div class="shequ-1">
						<div class="shequ-1a">卫生健康服务队</div>
						<div class="shequ-1b">{{ getdatavalue(444) }}</div>
					</div>
					<div class="shequ-1">
						<div class="shequ-1a">志愿服务队</div>
						<div class="shequ-1b">{{ getdatavalue(445) }}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { listData, getvalue } from '@/api/dtdata';
export default {
	components: {},
	props: ['dialog_click'],
	data() {
		return {
			dataOptions: [],
			queryParams: {
				dataModule: 'wj_happy_home',
				dataTyp: 'home_person_info',
			},
		};
	},
	components: {},
	computed: {},
	created() {
		listData(this.queryParams).then((response) => {
			this.dataOptions = response.rows;
		});
	},

	methods: {
		go() {
			let arr = [
				// { dataId: '245', label: '户籍人口', dataDetailed: this.getdatavalue(245) },
				// { dataId: '246', label: '常住人口', dataDetailed: this.getdatavalue(246) },
				// { dataId: '247', label: '本月迁入人数', dataDetailed: this.getdatavalue(247) },
				// { dataId: '248', label: '本月迁出人数', dataDetailed: this.getdatavalue(248) },
				// { dataId: '249', label: '本月出生人数', dataDetailed: this.getdatavalue(249) },
				// { dataId: '250', label: '本月死亡人数', dataDetailed: this.getdatavalue(250) },
				{ dataId: '431', label: '总人口', dataDetailed: this.getdatavalue(431) },
				{ dataId: '432', label: '劳动力', dataDetailed: this.getdatavalue(432) },
				{ dataId: '433', label: '区内就业', dataDetailed: this.getdatavalue(433) },
				{ dataId: '434', label: '区外就业', dataDetailed: this.getdatavalue(434) },
			];
			this.$emit('dialog_click', '人口概况', arr);
		},
		go1() {
			let arr = [
				// { dataId: '251', label: '独生子女', dataDetailed: this.getdatavalue(251) },
				// { dataId: '307', label: '独生子女父母奖励金', dataDetailed: this.getdatavalue(307) },
				// { dataId: '252', label: '失独老人', dataDetailed: this.getdatavalue(252) },
				// { dataId: '308', label: '政府补贴', dataDetailed: this.getdatavalue(308) },
				// { dataId: '253', label: '低保户(户数)', dataDetailed: this.getdatavalue(253) },
				// { dataId: '254', label: '低保户(人数)', dataDetailed: this.getdatavalue(254) },
				// { dataId: '255', label: '优抚对象(人数)', dataDetailed: this.getdatavalue(255) },
				// { dataId: '256', label: '优抚对象(就业率)', dataDetailed: this.getdatavalue(256) },
				// { dataId: '257', label: '低收入(户数)', dataDetailed: this.getdatavalue(257) },
				// { dataId: '258', label: '低收入(人数)', dataDetailed: this.getdatavalue(258) },
				{ dataId: '435', label: '低保户(户数)', dataDetailed: this.getdatavalue(435) },
				{ dataId: '436', label: '低保户(人数)', dataDetailed: this.getdatavalue(436) },
				{ dataId: '437', label: '残疾人士', dataDetailed: this.getdatavalue(437) },
				{ dataId: '438', label: '精神障碍人士', dataDetailed: this.getdatavalue(438) },
			];
			this.$emit('dialog_click', '人口概况', arr);
		},
		go2() {
			let arr = [
				{ dataId: '439', label: '应急救援服务队', dataDetailed: this.getdatavalue(439) },
				{ dataId: '440', label: '政策宣讲服务队', dataDetailed: this.getdatavalue(440) },
				{ dataId: '441', label: '创业就业服务队', dataDetailed: this.getdatavalue(441) },
				{ dataId: '442', label: '心理疏导服务队', dataDetailed: this.getdatavalue(442) },
				{ dataId: '443', label: '文体文艺服务队', dataDetailed: this.getdatavalue(443) },
				{ dataId: '444', label: '卫生健康服务队', dataDetailed: this.getdatavalue(444) },
				{ dataId: '445', label: '志愿服务队', dataDetailed: this.getdatavalue(445) },
			];
			this.$emit('dialog_click', '人口概况', arr);
		},
		getdatavalue(id) {
			return getvalue(this.dataOptions, id);
		},
	},
};
</script>

<style scoped lang="scss">
.idx-middle-box3 {
	width: 100%;
	height: 45vh;
	overflow: hidden;
	//   border: 1px solid tan;
	.idx-middle-1 {
		width: 100%;
		height: 6vh;
		line-height: 6vh;
		text-align: center;
		font-size: 0.9vw;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #ffd901;
	}
	.idx-middle-2 {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		align-items: center;
		margin-top: 2vh;
		.idx-middle-2a {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			line-height: 3vh;
			span {
				&:first-child {
					font-size: 1.3vw;
					font-family: hy;
					font-weight: bold;
					font-style: italic;
					color: #ffd901;
				}
				&:last-child {
					font-size: 0.7vw;
					font-family: Adobe Heiti Std;
					font-weight: normal;
					color: #eeab1a;
				}
			}
		}
		.idx-middle-2b {
			font-size: 0.7vw;
			font-family: Adobe Heiti Std;
			font-weight: normal;
			color: #ffffff;
			line-height: 3vh;
			.rk-s1 {
				font-size: 1.2vw;
				font-family: hy;
				font-weight: bold;
				font-style: italic;
				color: #ffd901;
				padding: 0 1vw;
			}
		}
	}
	.idx-middle-3 {
		width: 100%;
		height: 30vh;
		overflow: hidden;
		// border: 1px solid tan;
		.idx-middle-3-L {
			width: 50%;
			height: 30vh;
			overflow: hidden;
			//   border: 1px solid tan;
			float: left;
			padding-top: 2vh;
			/* padding-left: 2vw; */
			/* display: flex;
			justify-content: center; */
			ul li {
				width: 100%;
				height: 8.5vh;
				line-height: 2.6vh;

				//   border: 1px solid teal;
				/* float: left; */
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				div {
					span {
						font-size: 1.2vw;
						font-family: hy;
						font-weight: bold;
						font-style: italic;
						color: #fff7c8;
						padding-right: 0.5vw;

						// &:first-child {
						//   font-size: 1.2vw;
						//   font-family: hy;
						//   font-weight: bold;
						//   font-style: italic;
						//   color: #fff7c8;
						//   padding-right: 0.5vw;
						// }
						// &:last-child {
						//   font-size: 0.6vw;
						//   font-weight: bold;
						//   font-style: normal;
						//   color: rgba(4, 243, 238, 1);
						// }
					}
					.idx-s3 {
						font-family: Adobe Heiti Std;
						font-size: 0.8vw;
						font-weight: bold;
						font-style: normal;
						color: rgba(4, 243, 238, 1);
					}
					.idx-s3a {
						font-size: 1.2vw;
						font-family: hy;
						font-style: italic;
					}
					.idx-s3b {
						font-family: Adobe Heiti Std;
						font-size: 0.8vw;
					}
				}
				.sq-box {
					font-size: 0.7vw;
					font-family: Adobe Heiti Std;
					font-weight: normal;
					color: #eeab1a;
				}
			}
		}
		.idx-middle-3-R {
			width: 45%;
			height: 30vh;
			overflow: hidden;
			padding-top: 2vh;
			.idx-middle-3-R-T {
				width: 100%;
				font-size: 0.9vw;
				padding: 1vh 1vw;
				font-family: Adobe Heiti Std;
				font-weight: normal;
				color: #05e1ee;
				display: flex;
				align-items: center;
				justify-content: space-between;
				.right {
					color: #eeab1a;
				}
				// border: 1px solid tan;
			}
			.idx-middle-3-R-B {
				width: 88%;
				border: 1px solid rgba(86, 111, 236, 1);
				margin: 0 auto;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				.shequ-1 {
					width: 100%;
					height: 3vh;
					line-height: 3vh;
					border-bottom: 1px solid rgba(86, 111, 236, 1);
					font-size: 0.7vw;
					color: #fff;
					display: flex;
					flex-direction: row;
					justify-content: space-around;
					align-items: center;
					.shequ-1a {
						width: 60%;
						text-align: center;
						border-right: 1px solid rgba(86, 111, 236, 1);
					}
					.shequ-1b {
						width: 40%;
						text-align: center;
					}
				}
				.shequ-title {
					width: 100%;
					text-align: left;
					padding-left: -12vw;
					display: inline-block;
					padding-left: 3vw;
				}
			}
		}
	}
}
</style>
